import Link from 'next/link';
import { Text } from './Text';

type BreadCrumbsProps = {
    breadcrumbs: { label: string; href?: string }[];
};

export const BreadCrumbs = (props: BreadCrumbsProps) => {
    const { breadcrumbs } = props;

    return (
        <div className="flex items-center">
            <Link href="/">
                <Text fontSize="sm">Home</Text>
            </Link>
            {breadcrumbs.map((breadcrumb, index) => (
                <div key={`breadcrumb_${index}`}>
                    {index + 1 < breadcrumbs.length ? (
                        <Link href={breadcrumb.href!}>
                            <Text className="ml-4">&gt;</Text>
                            <Text className="ml-4">{breadcrumb.label}</Text>
                        </Link>
                    ) : (
                        <>
                            <Text className="ml-4">&gt;</Text>
                            <Text className="ml-4" fontColor="gray" fontSize="sm">
                                {breadcrumb.label}
                            </Text>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};
